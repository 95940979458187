const targetDate = Date.UTC(2022, 1, 2, 2, 2, 22, 0);
const countdownElement = document.getElementById("countdown");
const
    countdownElementDays = countdownElement.querySelector(".days"),
    countdownElementHours = countdownElement.querySelector(".hours"),
    countdownElementMinutes = countdownElement.querySelector(".minutes"),
    countdownElementSeconds = countdownElement.querySelector(".seconds");

function updateCountdown() {
    let now = Date.now();
    let difference = targetDate - now;

    countdownElementDays.innerHTML = Math.floor(difference / (1000 * 60 * 60 * 24)).toString();
    countdownElementHours.innerHTML = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
    countdownElementMinutes.innerHTML = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toString();
    countdownElementSeconds.innerHTML = Math.floor((difference % (1000 * 60)) / 1000).toString();

    countdownElement.classList.remove("hidden");
}

updateCountdown();
setInterval(function() {
    updateCountdown();
}, 1000);

function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.msMaxTouchPoints > 0));
}

import "./snow";

snow.start({
    flakeCount: isTouchDevice() ? 45 : 150,
    color: "#F0F9FF",
});
